import React from 'react';

import './TrashIcon.scss';

const TrashIcon = () => {
    return (
        <svg className="trash-logo" enableBackground="new 0 0 500 500" version="1.1" viewBox="0 0 500 500" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="101.642" x2="120.633" y1="134.309" y2="430.972"/>
                <path d="   M120.633,430.972c0,10.5,8.519,19.031,18.992,19.031" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="139.625" x2="360.389" y1="450.003" y2="450.003"/>
                <path d="   M360.389,450.003c10.474,0,18.979-8.531,18.979-19.031" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <polyline fill="none" points="   379.367,430.972 398.386,134.309 101.642,134.309  " strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <path d="   M432.779,115.973c0,10.059-8.143,18.215-18.188,18.215" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="414.592" x2="85.409" y1="134.188" y2="134.188"/>
                <path d="   M85.409,134.188c-10.018,0-18.188-8.156-18.188-18.215" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <path d="   M67.221,115.973c0-10.019,8.17-18.188,18.188-18.188" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="85.409" x2="414.592" y1="97.784" y2="97.784"/>
                <path d="   M414.592,97.784c10.045,0,18.188,8.17,18.188,18.188" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="307.364" x2="307.364" y1="97.49" y2="68.988"/>
                <path d="   M307.364,68.988c0-10.474-8.505-18.991-18.965-18.991" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="288.399" x2="211.628" y1="49.997" y2="49.997"/>
                <path d="   M211.628,49.997c-10.487,0-18.979,8.518-18.979,18.991" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
                <polyline fill="none" points="   192.649,68.988 192.649,97.49 307.364,97.49  " strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10"/>
            </g>
            <g>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="166.332" x2="166.332" y1="172.278" y2="406.06"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="222.102" x2="222.102" y1="172.278" y2="406.06"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="277.926" x2="277.926" y1="172.278" y2="406.06"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="2.6131" strokeWidth="10" x1="333.669" x2="333.669" y1="172.278" y2="406.06"/>
            </g>
        </svg>
    );
}

export default TrashIcon;