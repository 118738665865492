import React from 'react';

import './NewConversation.scss';

const NewConversation = () => {
    return (
        <div id="new-message-container">
            <button>+</button>
        </div>
    );
}

export default NewConversation;